import React, {useState, Fragment, useEffect} from "react";
import { Link } from "gatsby";
import { Dialog, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";

const maxHeaderItems = 5;
const defaultLogo = 'https://tailwindui.com/img/logos/workflow-logo-indigo-300-mark-white-text.svg';

const Header = ({ headerItems }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [logo, setLogo] = useState(defaultLogo);
  let customLogo;
  try {
    customLogo = require('../../../static/assets/logo.png');
  } catch (_error) {
    console.log('Custom logo image not found at `./static/assets/logo.png`')
  }


  useEffect(() => {
    if (customLogo?.default) {
      setLogo(customLogo?.default);
    }
  }, [customLogo])

  return (
    <>
      {headerItems.length >= maxHeaderItems ? (
        <div className="fixed z-40">
          <Transition.Root show={sidebarOpen} as={Fragment}>
            <Dialog
              as="div"
              className="fixed inset-0 flex z-40 md:hidden"
              onClose={setSidebarOpen}
            >
              <Transition.Child
                as={Fragment}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
              </Transition.Child>
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <div className="relative flex-1 flex flex-col max-w-xs w-full bg-indigo-700">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                    <div className="flex-shrink-0 flex items-center px-4">
                      <Link to="/">
                        <img
                          className="h-8 w-auto"
                          src={logo}
                          alt=""
                        />
                      </Link>
                    </div>
                    <nav className="mt-5 px-2 space-y-1">
                      {headerItems.map((item) => (
                        <Link
                          key={item.title}
                          to={item.url}
                          className="text-white hover:bg-indigo-600 hover:bg-opacity-75 group flex items-center px-2 py-2 text-base font-medium rounded-md"
                          activeClassName="bg-indigo-800 text-white group flex items-center px-2 py-2 text-base font-medium rounded-md"
                        >
                          {item.title}
                        </Link>
                      ))}
                    </nav>
                  </div>
                </div>
              </Transition.Child>
              <div className="flex-shrink-0 w-14" aria-hidden="true">
                {/* Force sidebar to shrink to fit close icon */}
              </div>
            </Dialog>
          </Transition.Root>

          {/* Static sidebar for desktop */}
          <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
            <div className="flex-1 flex flex-col min-h-0 bg-indigo-700">
              <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
                <div className="flex items-center flex-shrink-0 px-4">
                  <Link to="/">
                    <img
                      className="h-8 w-auto"
                      src={logo}
                      alt=""
                    />
                  </Link>
                </div>
                <nav className="mt-5 flex-1 px-2 space-y-1">
                  {headerItems.map((item) => (
                    <Link
                      key={item.title}
                      to={item.url}
                      className="text-white hover:bg-indigo-600 hover:bg-opacity-75 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                      activeClassName="bg-indigo-800 text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                    >
                      {item.title}
                    </Link>
                  ))}
                </nav>
              </div>
            </div>
          </div>
          <div className="md:pl-64 flex flex-col flex-1">
            <div className="sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 bg-gray-100">
              <button
                type="button"
                className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                onClick={() => setSidebarOpen(true)}
              >
                <span className="sr-only">Open sidebar</span>
                <MenuIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      ) : (
        <header className="relative z-50 bg-indigo-700">
          <nav className="sm:max-w-4xl mx-auto px-4" aria-label="Top">
            <div className="w-full py-6 flex items-center justify-between border-b border-indigo-600 lg:border-none">
              <div className="flex items-center">
                {/* Logo */}
                <Link to="/">
                  <span className="sr-only">Home</span>
                  <img
                    className="h-10 w-auto"
                    src={logo}
                    alt=""
                  />
                </Link>
                {/* Desktop navigation */}
                <div className="hidden ml-10 space-x-8 lg:block">
                  {headerItems.map((link) => (
                    <Link
                      key={link.title}
                      to={link.url}
                      className="text-base font-medium text-white hover:overline"
                      activeClassName="overline"
                    >
                      {link.title}
                    </Link>
                  ))}
                </div>
              </div>
            </div>
            {/* Mobile navigation */}
            <div className="py-4 flex flex-wrap justify-center space-x-6 lg:hidden">
              {headerItems.map((link) => (
                <Link
                  key={link.title}
                  to={link.url}
                  className="text-base font-medium text-white hover:overline"
                  activeClassName="overline"
                >
                  {link.title}
                </Link>
              ))}
            </div>
          </nav>
        </header>
      )}
    </>
  );
};

export default Header;
