import React, { useEffect, useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { MDXProvider } from "@mdx-js/react";

import PageTitle from "../components/elements/PageTitle";
import components from "../components/elements/StyledElements";
import Header from "../components/sections/Header";

const SingleArticleSection = ({ title, body }) => {
  const [headerItems, setHeaderItems] = useState(null);

  const headerItemsArray = [];
  const { allMdx } = useStaticQuery(graphql`
    query Articles {
      allMdx {
        nodes {
          frontmatter {
            title
          }
          fields {
            url
          }
        }
      }
    }
  `);

  useEffect(() => {
    if (allMdx && allMdx.nodes) {
      allMdx.nodes.forEach((node) => {
        const nodeInfo = {
          url: node.fields.url,
          title: node.frontmatter.title,
        };
        headerItemsArray.push(nodeInfo);
        if (!headerItemsArray.length) setHeaderItems([nodeInfo]);
        else setHeaderItems([...headerItemsArray]);
      });
    }
  }, [allMdx]);

  if (!headerItems) {
    return null;
  }

  return (
    <>
      <Header headerItems={headerItems} />
      <section
        className={`max-w-md max-w-4xl mx-auto relative mt-4 ${
          headerItems.length >= 5
            ? "md:ml-[16rem] 2xl:ml-auto mt-[68px] md:mt-4"
            : ""
        } overflow-hidden px-4`}
      >
        <PageTitle pageTitle={title} />

        <div className="relative">
          <div className="relative">
            <div className="text-lg prose prose-violet prose-lg text-gray-600 mx-auto">
              <MDXProvider components={components}>
                <MDXRenderer>
                  {/* Content of article */}
                  {body}
                </MDXRenderer>
              </MDXProvider>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SingleArticleSection;
